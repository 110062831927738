import React from 'react'
import { Container } from 'react-bootstrap'

function AboutBanner() {
  return (
    <div className='commonbanner'>
      <Container>
        <div className='aboutHero__heading'>
          {/* <h1>BEARWORLD</h1> */}
          <h1>COMING SOON</h1>
        </div>
        
      </Container>
    </div>
  )
}

export default AboutBanner
import React from 'react'
import { Container } from 'react-bootstrap';

function Beta() {
  return (
    <Container>
        <div id="beta">
        <button className='beta__button'>RESTRICTED ACCESS!</button>
        </div>
    </Container>
  )
}

export default Beta
import React, { useState, useEffect } from 'react'
import searchIcon from "../assets/images/search.svg"
import ethIcon from "../assets/images/ethIcon.svg"
import redirect from "../assets/images/redirect.svg"
import logo from "../assets/images/logo.png"
import x2y2 from "../assets/images/x2y2.png"
import looksrare from "../assets/images/looksrare.png"
import opensea from "../assets/images/opensea.svg"
import loadingGif from "../assets/images/loader.gif"
import { Link } from 'react-router-dom'

import axios from 'axios'
import moment from 'moment'

function Marketplace() {

  const [item, setItem] = useState(true)
  const [collectionData, setCollectionData] = useState([]);
  const [ITEMS, setITEMS] = useState([]);
  const [offset, setOffset] = useState("");
  const [properties, setProperties] = useState([]);
  const [activities, setActivities] = useState([]);
  const [showActivity, setShowActivity] = useState(false);
  const [activityOffset, setactivityOffset] = useState("");
  const [filters, setFilters] = useState([]);
  const [filters2, setFilters2] = useState([]);


  async function collectionDetails() {
    try {
      await axios.get(`https://api.reservoir.tools/collection/v2?id=0xe22e1e620dffb03065cd77db0162249c0c91bf01`)
        .then(res => {
          if (res.data) {
            setCollectionData(res.data)
          }

        })

    } catch (error) {
      console.log(error);
    }
  }

  async function fetchItems() {
    try {
      const config = {
        headers: {
          method: 'GET',
          headers: { Accept: '*/*', 'x-api-key': '0ac90295-d8c7-4032-a621-cc5c0e170077' }
        }
      };

      let API = "https://api.reservoir.tools/tokens/v4?collection=0xe22e1e620dffb03065cd77db0162249c0c91bf01&sortBy=floorAskPrice&limit=20"
      let APIquery = "&continuation="
      if (offset != "") {
        APIquery += offset;
        API += APIquery
      }
      console.log("API");
      console.log(API);
      await axios.get(`${API}`)
        .then(res => {
          console.log(res.data);
          if (res?.data?.tokens) {
            setITEMS((list) => [...(list), ...res?.data?.tokens]);

          }
          if (res?.data?.continuation) {
            setOffset(res?.data?.continuation)
          }
          if (res?.data?.continuation == null) {
            setOffset(res?.data?.continuation)
          }
        })

    } catch (error) {
      console.log(error);

    }
  }

  function addToFilter(key1, value) {
    alert(key1)
    alert(value)
    // FilterItems(`&attributes[${property?.key}]=${item?.value}`)
    setFilters([...filters, `&attributes[${key1}]=${value}`])
    const tempObj = {};
    tempObj[key1] = value

    setFilters2([...filters2, tempObj])

    FilterItems([...filters, `&attributes[${key1}]=${value}`])
  }

  async function FilterItems(filter) {
    try {
      const config = {
        headers: {
          method: 'GET',
          headers: { Accept: '*/*', 'x-api-key': '0ac90295-d8c7-4032-a621-cc5c0e170077' }
        }
      };

      let API = "https://api.reservoir.tools/tokens/v4?collection=0xe22e1e620dffb03065cd77db0162249c0c91bf01&sortBy=floorAskPrice&limit=20"

      let myFilters = filter;
      API += myFilters
      console.log("API");
      console.log(API);

      await axios.get(`${API}`)
        .then(res => {
          console.log("res.data FILTER");
          console.log(res.data);
          if (res?.data?.tokens) {
            setITEMS(res?.data?.tokens);

          }
          if (res?.data?.continuation) {
            setOffset(res?.data?.continuation)
          }
          if (res?.data?.continuation == null) {
            setOffset(res?.data?.continuation)
          }
        })

    } catch (error) {
      console.log(error);
    }
  }

  async function fetchProperties() {
    try {
      await axios.get(`https://api.reservoir.tools/collections/0xe22e1e620dffb03065cd77db0162249c0c91bf01/attributes/all/v2`)
        .then(res => {
          if ((res.data.attributes).length > 0) {
            setProperties(res.data.attributes)

          }
        })
        .catch(err => {
          console.log(err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  async function Activity() {
    try {
      const config = {
        headers: {
          method: 'GET',
          headers: { Accept: '*/*', 'x-api-key': '0ac90295-d8c7-4032-a621-cc5c0e170077' }
        }
      };

      let API = "https://api.reservoir.tools/sales/v3?contract=0xe22e1e620dffb03065cd77db0162249c0c91bf01&limit=20"
      let APIquery = "&continuation="
      if (activityOffset != "") {
        APIquery += activityOffset;
        API += APIquery
      }
      console.log("API");
      console.log(API);
      await axios.get(`${API}`)
        .then(res => {
          console.log(res.data?.sales);
          if ((res?.data?.sales).length > 0) {
            setActivities((list) => [...(list), ...res?.data?.sales]);

          }
          if (res?.data?.continuation) {
            setactivityOffset(res?.data?.continuation)
          }
          if (res?.data?.continuation == null) {
            setactivityOffset(res?.data?.continuation)
          }
          setShowActivity(true)
        })

    } catch (error) {
      console.log(error);
      setShowActivity(true)

    }
  }

  function convertTime(time) {
    console.log(time);
    var a = new Date(time * 1000);
    return moment(`${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}:${a.getSeconds()}`).fromNow();
  }


  useEffect(() => {
    collectionDetails()
    fetchProperties()
    fetchItems()
    Activity()

  }, [])

  return (
    <>
      <section className='marketPlaceMain'>
        <div className="dataOverviewMain">
          <div className='data'>
            <h4>{collectionData?.collection?.tokenCount}</h4>
            <p>ITEMS</p>
          </div>
          {/* <div className='data'>
            <h4>5,001</h4>
            <p>Top Offer</p>
          </div> */}
          <div className='data'>
            <h4>{collectionData?.collection?.floorAsk?.price}</h4>
            <p>Floor</p>
          </div>
          <div className='data'>
            <h4>{parseInt(collectionData?.collection?.volume?.allTime)}</h4>
            <p>Total Volume</p>
          </div>
        </div>

        <div className="container">
          <div className="marketMain">
            <div className='itemBtnDiv'>
              <button className='btn m-2 showmore' onClick={() => setItem(true)}>Items</button>
              <button className='btn m-2 showmore' onClick={() => setItem(false)}>Activity</button>
            </div>
            {
              item ?
                <div className="row">
                  <div className=" col-md-3 col-xl-4 col-xxl-3">
                    <div className="dropDownCard mb-3">
                      <button className='title btn' onClick={() => window.location.reload()}>RESET FILTER</button>
                    </div>

                    {
                      properties.length > 0 && properties.map((property, i) => {
                        return (
                          <div className="dropDownCard mb-3" key={i}>
                            <button className="btn title" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="false" aria-controls={`collapse${i}`}>
                              {property?.key} <span style={{ float: "right" }}> {property?.attributeCount}</span>
                            </button>
                            <div className="collapse" id={`collapse${i}`}>
                              <div className="card-body">
                                <div className="contentMain">
                                  {
                                    property?.values.length > 0 && property?.values?.map((item, i) => {
                                      return (
                                        <button className='selectContent' key={i} onClick={() => addToFilter(property?.key, item?.value)}>
                                          <span>{item?.value}</span>
                                          <span>{item?.count}</span>
                                        </button>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>

                  <div className=" col-md-9 col-xl-8 col-xxl-9">
                    <div className="priceMain">
                      <div className="col1">
                        <div>
                          {
                            filters2.map((item, i) => {
                              return (
                                < span key={ i } style={{ border: "2px solid #ffd800", padding: "7px 5px", borderRadius: "10px", marginRight: "15px", fontSize: "7px"}}>
                                  { Object.keys(item)}: {Object.values(item)}
                                  <button style={{ backgroundColor: "transparent", border: "none", color: "#ffd800", fontSize: "10px"}}>
                                    X
                                  </button>
                                </span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                    <div className="nftCardMain">
                      <div className="row">
                        {
                          ITEMS.length > 0 && ITEMS.map((item, i) => {
                            return (
                              <NftCard key={i} NFT={item} />
                            )
                          })
                        }
                      </div>
                      <div className="row text-align-center">
                        <div className='itemBtnDiv my-5'>
                          {
                            offset != "" && offset &&
                            <button className='btn showmore' onClick={() => fetchItems()} > Show more</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="table-responsive itemTableMain mt-4">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <td scope="col">EVENT</td>
                        <td scope="col">ITEM</td>
                        <td scope="col">PRICE</td>
                        <td scope="col">FROM</td>
                        <td scope="col">TO</td>
                        <td scope="col">Time</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        showActivity ? activities.map((activity, i) => {
                          return (
                            <tr>
                              <td >
                                <div className="eventDiv">
                                  <img src={activity?.orderSource == "OpenSea" ? opensea : activity?.orderSource == "X2Y2" ? x2y2 : activity?.orderSource == "LooksRare" ? looksrare : logo} alt={activity?.orderSource} />
                                  {activity?.orderSide == "ask" ? "SALE" : activity?.orderSide == "bid" ? "OFFER ACCEPTED" : "---"}
                                </div>
                              </td>
                              <td>
                                <div className="itemDiv">
                                  <div className="imgdiv">
                                    <img src={activity?.token?.image} alt="nft" />
                                  </div>
                                  <Link style={{ color: "white", textDecoration: "none" }} to={`/marketplace/detail/${activity?.token?.tokenId}`}> BearXLabs {(activity?.token?.name)?.indexOf("MiniBearX") >= 0 ? (activity?.token?.name).split("MiniBearX")[1] : (activity?.token?.name)} </Link>
                                </div>
                              </td>
                              <td>
                                <div className="priceDiv">
                                  <img src={ethIcon} alt="nft" />
                                  {activity?.price}
                                </div>
                              </td>
                              <td><div className="address">	{(activity?.from).substring(0, 4)}...{(activity?.from).substring(38, 42)}</div></td>
                              <td><div className="address">	{(activity?.to).substring(0, 4)}...{(activity?.to).substring(38, 42)}</div></td>
                              <td>{convertTime(activity?.timestamp)} <a target="blank" href={`https://etherscan.io/tx/${activity?.txHash}`}> <img src={redirect} /> </a> </td>
                            </tr>
                          )
                        })

                          :
                          <div className='loadingGifDiv' style={{ textAlign: "center" }}>
                            <img src={loadingGif} alt="loadingGif" />
                          </div>
                      }
                    </tbody>
                  </table>
                  <div className="row text-align-center">
                    <div className='itemBtnDiv my-5'>
                      {
                        activityOffset != "" && activityOffset &&
                        <button className='btn showmore' onClick={() => Activity()} > Show more</button>
                      }
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </section>
    </>
  )
}

const NftCard = ({ NFT }) => {
  return (
    <div className="col-sm-6 col-lg-4 col-xxl-3">
      <Link style={{ textDecoration: "none" }} to={`/marketplace/detail/${NFT?.tokenId}`}>
        <div className="nftCard">
          <div className="imgDiv">
            <img src={NFT?.image} alt="img" />
          </div>
          <div className="infoMain">
            <p className="id">BearX {NFT?.name}</p>
            <div className="contentDiv">
              {
                NFT?.floorAskPrice &&
                <div className="priceDiv">
                  <p>PRICE</p>
                  <div className='amount'><img src={ethIcon} alt="eth" /> {NFT?.floorAskPrice} </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Link>

    </div>
  )
}

export default Marketplace
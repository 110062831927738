import React, { useEffect, useState } from "react";
import { Contract, ethers, BigNumber } from "ethers";
import NumberFormat from "react-number-format";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import SROOTxABI from "../../config/srootxABI.json";
import SRootx_RootxABI from "../../config/SRootx_RootxABI.json";
import BearXStakingABI from "../../config/stakingABI.json";
import Modal from "react-awesome-modal";
import loading from "../../assets/images/loading.gif";
import contract from "../../config/contract";

const BurnROOTxToGetSROOTxComponent = () => {
  const [MyWeb3, setMyWeb3] = useState([]);
  const [myAccount, setMyAccount] = useState([]);
  const [maxSROOTx, setMaxSROOTx] = useState(1);
  const [SROOTx, setSROOTx] = useState(1);
  const [maxbtnpending, setMaxbtnpending] = useState(false);
  const [burnbtnpending, setBurnbtnpending] = useState(false);
  const [approvebtnpending, setApprovebtnpending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (window.web3 !== undefined && window.ethereum) {
      loadWeb3();
    }
  }, []);

  useEffect(() => {
    if (MyWeb3.length !== 0) {
      getbalanceOfrootx();
    }
  }, [MyWeb3, myAccount[0]]);

  const loadWeb3 = async () => {
    const web3 = await new ethers.providers.Web3Provider(window.ethereum);
    await web3
      .listAccounts()
      .then((acc) => {
        setMyWeb3(web3);
        setMyAccount(acc);
      })
      .catch((err) => console.log(err));
  };

  async function getbalanceOfrootx() {
    if (myAccount.length === 0) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const SRootxContract = new Contract(
      contract.SROOTx[1],
      SROOTxABI,
      provider?.getSigner()
    );

    await SRootxContract
      .balanceOf(myAccount[0])
      .then((r) => {
        var result = r / 1000000000000000000;
        setSROOTx(result);
      })
      .catch((err) => {
        setSROOTx(0);
        console.log(err);
      });
  }

  const setIMaxSROOTx = (value) => {
    if (maxSROOTx >= 1) {
      if (value === 0) {
        return;
      } else if (value > SROOTx) {
        return;
      } else {
        setMaxSROOTx(value);
      }
    }
  };

  async function approveSRootx() {
    if (myAccount.length === 0) {
      return;
    } else if (SROOTx === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Insufficient Token!",
      });
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const SRootxContract = new Contract(
      contract.SROOTx[1],
      SROOTxABI,
      provider?.getSigner()
    );

    const myAllowanceTx = await SRootxContract.allowance(
      myAccount[0],
      contract.SRootx_Rootx[1]
    );
    const myAllowance = myAllowanceTx.toString() / 1000000000000000000;
    if (myAllowance >= SROOTx) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You already approved your token!",
      });
    } else {
      Swal.fire({
        icon: "question",
        title: "Confirm SROOTx Approving",
        text: `You will approve ${SROOTx} SROOTx token, continue ?`,
        showCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          setApprovebtnpending(true);
          setShowModal(true);
          try {
            const tx = await SRootxContract.approve(
              contract.SRootx_Rootx[1],
              ethers.utils.parseUnits(String(SROOTx), 18),
              { from: myAccount[0] }
            );
            await tx.wait();
            window.location.reload();
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went Wrong!",
            });
            setApprovebtnpending(false);
            setShowModal(false);
          }
        }
      });
    }
  }
  
  async function burnrootx() {
    if (myAccount.length === 0) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const SRootx_Rootx_Swap = new Contract(
      contract.SRootx_Rootx[1],
      SRootx_RootxABI,
      provider?.getSigner()
    );

    let valueofrootx = document.getElementById("value_rootx").value;
    var bg_decimal = BigNumber.from("1000000000000000000");
    var bg_valueofrootx = BigNumber.from(valueofrootx);

    var temp = bg_decimal.mul(bg_valueofrootx);

    if (SROOTx !== 0) {
      setBurnbtnpending(true);
      setShowModal(true);
      try {
        const tx = await SRootx_Rootx_Swap.swap(temp, {
          from: myAccount[0],
        });
        await tx.wait();
        window.location.reload();
      } catch (err) {
        console.log(err.code);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.code == "ACTION_REJECTED" ? "Action Rejected" : "You have to approve enough SROOTx token to swap!",
        });
        setShowModal(false);
        setBurnbtnpending(false);
      }
    }
  }

  async function getmaxrootx() {
    if (myAccount.length === 0) return;

    setMaxbtnpending(true);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const SRootxContract = new Contract(
      contract.SROOTx[1],
      SROOTxABI,
      provider?.getSigner()
    );

    SRootxContract
      .balanceOf(myAccount[0])
      .then((r) => {
        var result = r / 1000000000000000000;
        setMaxSROOTx(result);
        setMaxbtnpending(false);
      })
      .catch((err) => {
        setMaxSROOTx(0);
        console.log(err);
        setMaxbtnpending(false);
      });
  }

  return (
    <>
      <div className="burn-get-maincontain">
        <div className="about__details">
          <p style={{ color: "yellow", fontSize: "25px" }}>
            Enter the amount of SROOTx you'd like to convert for ROOTx
          </p>
          <p style={{ color: "yellow", fontSize: "25px" }}>
            Your available SROOTx token is{" "}
            <NumberFormat
              value={SROOTx.toFixed(0)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </div>

        <div className="burn-get-subcontain">
          <div className="number">
            <button
              className="number-minus"
              type="button"
              onClick={() => setIMaxSROOTx(maxSROOTx - 1)}
            >
              -
            </button>
            <input
              type="number"
              placeholder="0"
              min={1}
              id="value_rootx"
              value={maxSROOTx.toFixed(0)}
              onChange={(e) => setMaxSROOTx(parseInt(e.target.value))}
            />
            <button
              className="number-plus"
              type="button"
              onClick={() => setIMaxSROOTx(maxSROOTx + 1)}
            >
              +
            </button>
          </div>

          {maxbtnpending ? (
            <button className="max-rootx-button" type="button">
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            </button>
          ) : (
            <button
              className="max-rootx-button"
              type="button"
              onClick={getmaxrootx}
            >
              Max
            </button>
          )}
        </div>
        <div className="burn-btn-get-subcontain">
          {approvebtnpending ? (
            <button className="approve-rootx-button" type="button">
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            </button>
          ) : burnbtnpending ? (
            <button
              className="approve-rootx-button"
              type="button"
              onClick={approveSRootx}
              disabled
            >
              Approve ALL
            </button>
          ) : (
            <button
              className="approve-rootx-button"
              type="button"
              onClick={approveSRootx}
            >
              Approve ALL
            </button>
          )}
          {burnbtnpending ? (
            <button className="burn-rootx-button" type="button">
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            </button>
          ) : approvebtnpending ? (
            <button
              className="burn-rootx-button"
              type="button"
              onClick={burnrootx}
              disabled
            >
              Convert
            </button>
          ) : (
            <button
              className="burn-rootx-button"
              type="button"
              onClick={burnrootx}
            >
              Convert
            </button>
          )}
        </div>
      </div>
      <Modal visible={showModal} width="450px" height="300px" effect="fadeInUp">
        <div style={{ marginLeft: "100px" }}>
          <img src={loading} alt="loading" />
        </div>
        <div className="about__details">
          <p style={{ color: "#fd7e14" }}>Processing...</p>
        </div>
      </Modal>
    </>
  );
};

export default BurnROOTxToGetSROOTxComponent;

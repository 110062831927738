import React from 'react';
import BearSteakOne from '../components/bearsteak/BearSteakOne';  
import BearSteakTwo from '../components/bearsteak/BearSteakTwo';  


function BearSteak() {
  return (
    <>
      <BearSteakOne />
      <BearSteakTwo />
    </>
  )
}

export default BearSteak